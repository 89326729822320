import React from 'react'
import { useTranslation } from 'react-i18next'
import DesktopBurn from '../../assets/ENG/game-theory-desktop-burn.svg'
import DesktopHold from '../../assets/ENG/game-theory-desktop-hold.svg'
import MobileBurn from '../../assets/ENG/game-theory-mobile-burn.svg'
import MobileHold from '../../assets/ENG/game-theory-mobile-hold.svg'
import HowItWorks from '../../assets/ENG/may-you-propser.svg'
import HowItWorksMobile from '../../assets/ENG/prosper-mobile.svg'
import RarityChance from '../../assets/ENG/rarity-chance.svg'
import { GlassCard, H1 } from '../../components'
import {
  AllCentered,
  BurnChoiceWrapper,
  BurnImgDesktop,
  BurnImgMobile,
  CenteredH2,
  CenterTextCard,
  ChoiceContainer,
  GameTheorySection,
  HeroButton,
  NoCenterH3,
  RollChance,
  RollChanceContainer,
  SectionBackground,
  ShortWidth,
  StyledBackground,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledHref,
  StyledTriple,
  TripleRowColumn,
} from '../../features/game-theory/components'

export const GameTheory: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledBackground>
      <SectionBackground>
        <ShortWidth>
          <GlassCard>
            <AllCentered>
              <H1>{t('prosper')}</H1>
              <StyledH2>{t('collectOrBurn')}</StyledH2>
              <BurnChoiceWrapper>
                <BurnImgDesktop src={HowItWorks} />
                <BurnImgMobile src={HowItWorksMobile} />
              </BurnChoiceWrapper>
              <HeroButton to="/chainbinders">
                {t('VIEW CHAINBINDERS')}
              </HeroButton>
            </AllCentered>
          </GlassCard>

          <TripleRowColumn>
            <StyledTriple>
              <GlassCard>
                <AllCentered>
                  <StyledH3>{t('totalNft')}</StyledH3>
                </AllCentered>
              </GlassCard>

              <GlassCard>
                <AllCentered>
                  <StyledH3>{t('totalRedeemNft')}</StyledH3>
                </AllCentered>
              </GlassCard>

              <GlassCard>
                <AllCentered>
                  <StyledH3>{t('totalFailedNft')}</StyledH3>
                </AllCentered>
              </GlassCard>
            </StyledTriple>
            <GlassCard>
              <RollChanceContainer>
                <RollChance src={RarityChance} alt="" />
              </RollChanceContainer>
            </GlassCard>
          </TripleRowColumn>
          <CenterTextCard>
            <StyledHref
              href="https://medium.com/@dokidoki.finance/introducing-chainbinders-a-next-generation-nft-gacha-experience-en-4eaaa942f329"
              target="__blank"
              rel="noreferrer"
            >
              {t('nftCalculation')}
            </StyledHref>
          </CenterTextCard>
        </ShortWidth>
      </SectionBackground>
      <GameTheorySection>
        <ShortWidth>
          <ChoiceContainer>
            <AllCentered>
              <StyledH1>{t('gameTheory')}</StyledH1>
              <StyledH2>{t('makeChoice')}</StyledH2>
            </AllCentered>

            <GlassCard>
              <StyledH2>1. {t('burnNft')}</StyledH2>
              <br />

              <NoCenterH3>{t('irreversible')}</NoCenterH3>

              <BurnChoiceWrapper>
                <BurnImgDesktop src={DesktopBurn} />
                <BurnImgMobile src={MobileBurn} />
              </BurnChoiceWrapper>
            </GlassCard>

            <GlassCard>
              <StyledH2>2. {t('collectAndHold')}</StyledH2>
              <br />

              <NoCenterH3>{t('collectorAmass')}</NoCenterH3>

              <BurnChoiceWrapper>
                <BurnImgDesktop src={DesktopHold} />
                <BurnImgMobile src={MobileHold} />
              </BurnChoiceWrapper>
            </GlassCard>
          </ChoiceContainer>
        </ShortWidth>
      </GameTheorySection>
    </StyledBackground>
  )
}

export default GameTheory
