import { Link } from 'gatsby'
import styled from 'styled-components'
import { GlassCard, H2 } from '../../components'
import Background from '../../images/blobs.svg'
import Background1 from '../../images/space-lines.png'

export const CenterTextCard = styled(GlassCard)`
  text-align: center;
`

export const StyledHref = styled.a`
  color: #25a5c2;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: #00d1ff;
  }
`

export const CenteredH2 = styled.h2`
  text-align: center;
  margin-top: 0;
  color: white;
  text-transform: capitalize;
  font-weight: 400;
`

export const SectionBackground = styled.div`
  padding-top: 6rem;
  padding-bottom: 1rem;
  background: url(${Background}) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
`

export const MainHeroButton = styled(Link)`
  color: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-radius: 32px;
  background: #00ffc2;
  font-weight: 700;
  font-size: 'Open Sans';
`

export const BurnImgMobile = styled.img`
  width: 100%;
  @media (min-width: 750px) {
    display: none;
  }
`

export const BurnImgDesktop = styled.img`
  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 750px) {
    display: inline-block;
    width: 75%;
  }
`

export const BurnChoiceWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ChoiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto 1fr;
  row-gap: 3rem;
`

export const StyledH3 = styled.h3`
  color: white;

  @media (min-width: 200px) {
    font-size: 1rem;
    text-align: center;
  }

  @media (min-width: 750px) {
    font-size: 1.075rem;
  }
`

export const NoCenterH3 = styled.h3`
  color: white;

  @media (min-width: 200px) {
    font-size: 1rem;
  }

  @media (min-width: 750px) {
    font-size: 1.075rem;
  }
`

export const GameTheorySection = styled.section`
  background: url(${Background1}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  padding-top: 5rem;
`

export const HeroButton = styled(Link)`
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-radius: 32px;
  background: rgb(0, 255, 194);
  background: linear-gradient(
    90deg,
    rgba(0, 255, 194, 1) 0%,
    rgba(0, 234, 223, 1) 100%
  );
  color: black;
  font-weight: 400;
  font-size: 'Open Sans';
  cursor: pointer;
  text-align: center;
  transition: 0.5s;

  @media (min-width: 200px) {
    font-size: 1rem;
  }

  @media (min-width: 750px) {
    font-size: 22px;
  }

  &:hover {
    box-shadow: 0 0 5px #00ffc2, 0 0 15px #00ffc2, 0 0 30px #00ffc2,
      0 0 60px #00ffc2;
  }
`

export const RollChance = styled.img`
  @media (min-width: 200px) {
    width: 100%;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`

export const RollChanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const StyledTriple = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 2rem;
`

export const TripleRowColumn = styled.div`
  display: grid;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    row-gap: 2rem;
  }
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    column-gap: 2rem;
  }
`

export const AllCentered = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const StyledH1 = styled.h1`
  color: white;
  font-weight: 500;
`
export const StyledH2 = styled.h2`
  color: white;
  font-weight: 400;

  @media (min-width: 200px) {
    font-size: 1.2rem;
  }
  @media (min-width: 750px) {
    font-size: 1.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`

export const StyledBackground = styled.section`
  width: 100vw;
  display: grid;
`

export const ShortWidth = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 85%;
  margin-bottom: 5rem;
  @media (min-width: 200px) {
  }
  @media (min-width: 750px) {
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 64px;
    padding-left: 64px;
  }
`
